import React from "react";

import { Avatar } from "components/UI";

import avatar from "images/wouterraateland.jpg";

export default function Bio() {
  return (
    <div className="flex items-center py-8 mb-4 space-x-4">
      <Avatar size={4} user={{ avatar }} />
      <div>
        <p className="text-xl">Wouter Raateland</p>
        <a className="text-xs" href="mailto:wouterraateland@gmail.com">
          wouterraateland@gmail.com
        </a>
      </div>
    </div>
  );
}
