import { graphql } from "gatsby";

import React from "react";
import useTranslation from "hooks/useTranslation";

import PageLayout from "layouts/Page";

import SEO from "components/SEO";
import Bio from "components/Bio";
import BlogPostPreview from "components/BlogPostPreview";

export default ({ data, pageContext }) => {
  const { t } = useTranslation();
  const post = data.markdownRemark;
  const { previous, next } = pageContext;

  return (
    <PageLayout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <div className="w-full max-w-2xl px-4 mx-auto">
        <article>
          <header>
            <p className="text-xs">
              {t("blog:meta", {
                author: "Wouter Raateland",
                date: post.frontmatter.date,
              })}
            </p>
            <h1>{post.frontmatter.title}</h1>
          </header>
          <section dangerouslySetInnerHTML={{ __html: post.html }} />
          <hr />
          <footer>
            <Bio />
          </footer>
        </article>

        <nav>
          <div className="flex flex-wrap items-stretch m--2">
            {previous && (
              <div className="w-64 p-4 m-2 shadow-sm border border-solid rounded-md border-accent">
                <p
                  className="text--label"
                  style={{ color: "#f219" }}
                >{t`blog:prev`}</p>
                <BlogPostPreview node={previous} />
              </div>
            )}
            {next && (
              <div className="w-64 p-4 m-2 shadow-sm border border-solid rounded-md border-accent">
                <p
                  className="text--label"
                  style={{ color: "#f219" }}
                >{t`blog:next`}</p>
                <BlogPostPreview node={next} />
              </div>
            )}
          </div>
        </nav>
      </div>
    </PageLayout>
  );
};

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`;
